import React from "react";
import * as S from './MensagemSchemaStyle';

export default function MensagemSchema({ data, handleClick, onDoubleClick }) {
    const handleVerMaisClick = () => {
        if (data.link) {
            window.open(data.link, '_blank');
        }
    };

    return (
        <S.MensagemVerBox
            onClick={() => handleClick && handleClick(data)}
            onDoubleClick={() => onDoubleClick && onDoubleClick()}
            messageType={data.messageType}
        >
            {data.messageType === "IMAGEM" ? (
                <S.ComImagem className="normal">
                    <h5>{data.diaData ? data.diaData : 'dd/mm/aaaa'}</h5>
                    <img src={data.image} alt="Selecionado" />
                    <h3>{data.message || "Mensagem"}</h3>
                    <h6>Converse Conosco</h6>
                </S.ComImagem>
            ) : (
                <>
                    <span>x</span>
                    <h1>{data.title || 'TÍTULO'}</h1>
                    <p>{data.message || 'MENSAGEM'}</p>
                    <div>
                        <h5>{data.diaData ? data.diaData : 'dd/mm/aaaa'}</h5>
                        <h6 onClick={handleVerMaisClick}>ver mais</h6>
                    </div>
                </>
            )}
        </S.MensagemVerBox>
    );
}
