import React, { useState } from 'react';
import * as S from './MensagemBoxStyle';
import { db, storage } from '../../../DATABASE/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import MensagemSchema from './MensagemSchema';

export default function MensagemBox({ onClose }) {
    const [messageType, setMessageType] = useState('AVISO');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [ver, setVer] = useState(false);
    const [link, setLink] = useState('');
    const [image, setImage] = useState("");

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
        }
    };

    const handleSave = async () => {
        if (messageType !== ''  && message !== '') {
            try {
                let imageURL = '';
                if (messageType === 'IMAGEM' && image) {
                    const file = document.querySelector('input[type="file"]').files[0];
                    imageURL = await handleUploadImage(file);
                }
    
                const formattedDate = (new Date());
                await addDoc(collection(db, 'MENSAGENS'), {
                    tipo: messageType,
                    titulo: title,
                    mensagem: message,
                    link: link,
                    data: formattedDate,
                    imagem: imageURL
                });
    
                alert("Mensagem salva com sucesso!");
                onClose(); // Fecha o modal após salvar
            } catch (error) {
                console.error("Erro ao salvar a mensagem: ", error);
                alert("Ocorreu um erro ao salvar a mensagem.");
            }
        } else {
            alert("Insira todos os campos para criar uma nova mensagem");
        }
    };
    

    const handleUploadImage = async (file) => {
        try {
            const storageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
    
            return new Promise((resolve, reject) => {
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        // Opcional: Você pode mostrar um progresso de upload aqui
                    },
                    (error) => {
                        console.error("Erro ao fazer upload da imagem: ", error);
                        reject(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            resolve(downloadURL);
                        });
                    }
                );
            });
        } catch (error) {
            console.error("Erro ao fazer upload da imagem: ", error);
            throw error;
        }
    };
    

    return (
        <S.ModalOverlay>
            <S.ModalContent>
                <S.CloseButton onClick={onClose}>X</S.CloseButton>
                <h2>NOVA MENSAGEM</h2>

                <S.DefineType>
                    <span>DEFINA O TIPO DA MENSAGEM</span>
                    <select value={messageType} onChange={(e) => setMessageType(e.target.value)}>
                        <option value='AVISO'>AVISO</option>
                        <option value='PROMOCAO'>PROMOÇÃO</option>
                        <option value='ATUALIZACAO'>ATUALIZAÇÃO</option>
                        <option value='NOTICIA'>NOTÍCIA</option>
                        <option value='IMAGEM'>COM IMAGEM</option>
                    </select>
                    <h6 onClick={() => setVer(!ver)}>{!ver ? 'ver' : 'fechar'}</h6>
                </S.DefineType>

                {messageType !== "IMAGEM" && (
                    <S.DefineTitle>
                        <span>DIGITE O TÍTULO</span>
                        <textarea
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                    </S.DefineTitle>
                )}

                <S.DefineText>
                    <span>DIGITE A MENSAGEM</span>
                    <textarea
                        maxLength={110}
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                    />
                </S.DefineText>

                {messageType === "IMAGEM" && (
                    <S.InputLink>
                        <span>SEL. A IMAGEM</span>
                        <S.SelectFile
                            onChange={handleImageChange}
                            type="file"
                        />
                    </S.InputLink>
                )}

                <S.InputLink>
                    <span>LINK</span>
                    <input
                        placeholder='cole seu link aqui'
                        type='text'
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                </S.InputLink>

                <S.CreateButton>
                    <button onClick={handleSave}>CRIAR</button>
                </S.CreateButton>
            </S.ModalContent>

            {ver && (
                <MensagemSchema data={{ messageType, title, message, link, image }} />
            )}
        </S.ModalOverlay>
    );
}
