import React, { useState, useEffect, useMemo } from "react";
import * as S from './ContratosStyle';
import { useDispatch, useSelector } from 'react-redux';
import { getDepositos, getAdminData, getSaques } from '../../redux/actions';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { formatDate, formatCurrencyBRL, calcularTempoPassado, areDatesEqual } from "../ASSETS/assets";
import PaginaContrato from "../PaginaDoContrato/PaginaContrato";
import { fetchClients } from "../../redux/clients/actions";
import * as XLSX from 'xlsx'; // Importa a biblioteca XLSX

const PAGE_SIZE = 10;

export default function Contratos() {
    const [search, setSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [yearFilter, setYearFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedContract, setSelectedContract] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [sortOrder, setSortOrder] = useState('crescente');
    const [exportFilter, setExportFilter] = useState('all');

    const dispatch = useDispatch();
    const depositos = useSelector((state) => state.DepositosReducer.depositos);

    const [adminData, setAdminData] = useState({
        totalCoinsPlataforma: 0,
        totalSaldoPlataforma: '0,00',
        totalDeGanhosPlataforma: '0,00',
        totalSaldoGolden: '0,00',
        error: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAdminData();
                setAdminData({
                    totalCoinsPlataforma: data.totalCoinsPlataforma || 0,
                    totalSaldoPlataforma: data.totalSaldoPlataforma || 0,
                    totalDeGanhosPlataforma: data.totalDeGanhosPlataforma || 0,
                    totalSaldoGolden: data.totalSaldoGolden || 0,
                    error: data.error || ''
                });
            } catch (error) {
                console.error('Failed to fetch admin data:', error);
                setAdminData({
                    totalCoinsPlataforma: 0,
                    totalSaldoPlataforma: 0,
                    totalDeGanhosPlataforma: 0,
                    totalSaldoGolden: 0,
                    error: 'Failed to fetch admin data'
                });
            }
        };
        fetchData();
    }, [dispatch]);

    const filteredClients = useMemo(() => {
        return depositos.filter(user => {
            const matchesSearch = (user.CLIENT_NAME && user.CLIENT_NAME.toUpperCase().includes(search.toUpperCase())) ||
                (user.CLIENT_CPF && user.CLIENT_CPF.toUpperCase().includes(search.toUpperCase())) ||
                (user.PURCHASEDATE && user.PURCHASEDATE.includes(search));
            const matchesStatus = statusFilter === '' ||
                (statusFilter === 'FINALIZADOS' && user.STATUS === 2) ||
                (statusFilter === 'VALORIZANDO' && user.STATUS === 1) ||
                (statusFilter === 'CANCELADOS' && user.STATUS === 3);

            const anoContrato = calcularTempoPassado(user.PURCHASEDATE).anos;
            const matchesYear = yearFilter === '' || anoContrato === parseInt(yearFilter);
            const matchesDate = dateFilter === null || areDatesEqual(user.PURCHASEDATE, dateFilter);
            const statusNotFour = user.STATUS !== 4;

            return matchesSearch && matchesStatus && matchesYear && matchesDate && statusNotFour;
        });
    }, [depositos, search, statusFilter, yearFilter, dateFilter]);

    const sortedClients = useMemo(() => {
        return filteredClients.sort((a, b) => {
            const dateA = new Date(a.PURCHASEDATE);
            const dateB = new Date(b.PURCHASEDATE);
            return sortOrder === 'crescente' ? dateA - dateB : dateB - dateA;
        });
    }, [filteredClients, sortOrder]);

    const totalPages = Math.ceil(sortedClients.length / PAGE_SIZE);
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const paginatedClients = sortedClients.slice(startIndex, endIndex);

    const debouncedSearch = useMemo(() => debounce((e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    }, 300), []);

    const valorGanho = (valorInvestido, lucroAtual) => {
        let valorINVESTIDO = (typeof valorInvestido === 'string' ? parseFloat(valorInvestido) : valorInvestido);
        let lucroATUAL = lucroAtual || 0;
        return ((lucroATUAL / 100) * valorINVESTIDO);
    };

    const handleSelectContract = (contract) => { setSelectedContract(contract); }
    const handleUnselectContract = () => { setSelectedContract(null); }

    const handleReload = async () => {
        await dispatch(fetchClients('recarregar'));
        await dispatch(getSaques());
        await dispatch(getDepositos());
    };

    const handleStatus = (status) => {
        switch (status) {
            case 1:
                return 'VALORIZANDO';
            case 2:
                return 'Finalizado';
            case 3:
                return 'Cancelado';
            case 4:
                return 'Pendente';
            default:
                return 'Indefinido';
        }
    };

    const handleExportToExcel = (filterType) => {
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
        const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);
        const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1);

        // Use sortedClients ao invés de paginatedClients
        const filteredData = sortedClients.filter(user => {
            const purchaseDate = new Date(user.PURCHASEDATE);
            if (filterType === "currentMonth") {
                return purchaseDate >= firstDayOfCurrentMonth;
            } else if (filterType === "previousMonth") {
                return purchaseDate >= firstDayOfPreviousMonth && purchaseDate < firstDayOfCurrentMonth;
            }
            return true; // Para "all", simplesmente retorna todos os dados
        });

        const data = filteredData.map(user => ({
            ID: user.IDCOMPRA,
            CLIENTE: user.CLIENT_NAME.toUpperCase(),
            CPF: user.CLIENT_CPF,
            'DATA DE COMPRA': formatDate(user.PURCHASEDATE),
            'QUANTIDADE COINS': user.COINS,
            'VALOR UNI.': user.COINVALUE.toFixed(2),
            'VALOR INVESTIDO': user.TOTALSPENT.toFixed(2),
            'LUCRO ATUAL (R$)': valorGanho(user.TOTALSPENT, user.RENDIMENTO_ATUAL).toFixed(2),
            'LUCRO ATUAL (%)': user.RENDIMENTO_ATUAL ? user.RENDIMENTO_ATUAL.toFixed(2) : '00.00%',
            'FINALIZA EM': formatDate(user.YIELDTERM),
            STATUS: handleStatus(user.STATUS)
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Contratos");

        // Gera o arquivo Excel
        XLSX.writeFile(workbook, 'tabela_contratos.xlsx');
    };



    return (
        <S.ContratosContainer>
            {selectedContract != null && (
                <PaginaContrato contratoData={selectedContract} handleClose={handleUnselectContract} />
            )}
            <S.HomeInitialContent>
                <S.PartTitle>Painel do Investidor - Modelo de Sistema</S.PartTitle>
                <S.Boxes>
                    <S.Box bgColor="#f2f2f2">
                        <S.BoxContent>
                            <S.BoxTitle>INVESTIMENTOS RECEBIDOS</S.BoxTitle>
                            <span>R$ {formatCurrencyBRL(adminData.totalSaldoGolden || 0)}</span>
                        </S.BoxContent>
                    </S.Box>
                    <S.Box bgColor="#f2f2f2">
                        <S.BoxContent>
                            <S.BoxTitle>QUANTIDADE TOTAL DE CONTRATOS</S.BoxTitle>
                            <span>{formatCurrencyBRL(parseInt(adminData.totalCoinsPlataforma))}</span>
                        </S.BoxContent>
                    </S.Box>
                    <S.Box bgColor="#f2f2f2">
                        <S.BoxContent>
                            <S.BoxTitle>GANHOS DOS CLIENTES</S.BoxTitle>
                            <span>R$ {formatCurrencyBRL(adminData.totalDeGanhosPlataforma)}</span>
                        </S.BoxContent>
                    </S.Box>
                </S.Boxes>
            </S.HomeInitialContent>

            <S.Contracts>
                <S.ContractsTitle>CONTRATOS</S.ContractsTitle>
                <S.SearchAreaContent>
                    <S.SearchArea>
                        <S.FilterDiv>
                            <h4>STATUS</h4>
                            <select onChange={(e) => setStatusFilter(e.target.value)}>
                                <option value="">TODOS</option>
                                <option value="FINALIZADOS">FINALIZADOS</option>
                                <option value="VALORIZANDO">VALORIZANDO</option>
                                <option value="CANCELADOS">CANCELADOS</option>
                            </select>
                        </S.FilterDiv>
                        <S.FilterDiv>
                            <h4>ANOS DE CONTRATO</h4>
                            <select onChange={(e) => setYearFilter(e.target.value)}>
                                <option value="">TODOS</option>
                                <option value="0">Menos de 1 Ano</option>
                                <option value="1">1 à 2 Anos</option>
                                <option value="2">2 à 3 Anos</option>
                                <option value="3">3 à 4 Anos</option>
                                <option value="4">4 à 5 Anos</option>
                            </select>
                        </S.FilterDiv>
                        <S.FilterDivException className="exceptionFilterDiv">
                            <S.TituloExcepitionDivo>POR DATA</S.TituloExcepitionDivo>
                            <div>
                                <input
                                    type="date"
                                    onChange={(e) => {
                                        setDateFilter(e.target.value);
                                        setCurrentPage(1);
                                    }}
                                />
                                <button onClick={() => {
                                    setDateFilter(null);
                                    setCurrentPage(1);
                                }}>
                                    Limpar
                                </button>
                            </div>
                        </S.FilterDivException>
                    </S.SearchArea>

                    <S.SecondSearchBar>
                        <input
                            type="text"
                            placeholder="Nome Do Cliente ou Data de Compra"
                            onChange={debouncedSearch}
                        />
                    </S.SecondSearchBar>
                </S.SearchAreaContent>
            </S.Contracts>

            <S.OrderSelect>
                <select className="selecioneOrdenacao" onChange={(e) => setSortOrder(e.target.value)}>
                    <option value="crescente">Crescente</option>
                    <option value="decrescente">Decrescente</option>
                </select>

                <div className="divPaiFilter">
                    <select className="selectExtrairTabela" onChange={(e) => setExportFilter(e.target.value)}>
                        <option value="currentMonth">Extrair Mês Atual</option>
                        <option value="previousMonth">Extrair Mês Anterior</option>
                        <option value="all">Extrair Tabela Completa</option>
                    </select>

                    <button className="botaoExtrairTabela" onClick={() => handleExportToExcel(exportFilter)}>
                        Extrair
                    </button>
                </div>
            </S.OrderSelect>

            <S.TableContainer>
                <S.Table>
                    <S.TableHeader>
                        <S.TableRow>
                            <S.TableHeaderCell>DATA DE COMPRA</S.TableHeaderCell>

                            <S.TableHeaderCell>ID</S.TableHeaderCell>
                            <S.TableHeaderCell>CLIENTE</S.TableHeaderCell>
                            <S.TableHeaderCell>CPF</S.TableHeaderCell>
                            <S.TableHeaderCell>QUANTIDADE COINS</S.TableHeaderCell>
                            <S.TableHeaderCell>VALOR UNI.</S.TableHeaderCell>
                            <S.TableHeaderCell>VALOR INVESTIDO</S.TableHeaderCell>
                            <S.TableHeaderCell>LUCRO ATUAL (R$)</S.TableHeaderCell>
                            <S.TableHeaderCell>LUCRO ATUAL (%)</S.TableHeaderCell>
                            <S.TableHeaderCell>FINALIZA EM</S.TableHeaderCell>
                            <S.TableHeaderCell>STATUS</S.TableHeaderCell>
                        </S.TableRow>
                    </S.TableHeader>
                    <S.TableBody>
                        {paginatedClients.map((user, index) => (
                            <S.TableRow key={index} onClick={() => { handleSelectContract(user); }}>
                                <S.TableCell>{formatDate(user.PURCHASEDATE)}</S.TableCell>

                                <S.TableCell>{user.IDCOMPRA}</S.TableCell>
                                <S.TableCell>{user.CLIENT_NAME.toUpperCase()}</S.TableCell>
                                <S.TableCell>{user.CLIENT_CPF}</S.TableCell>
                                <S.TableCell>{user.COINS}</S.TableCell>
                                <S.TableCell>R$ {user.COINVALUE.toFixed(2)}</S.TableCell>
                                <S.TableCell>R$ {user.TOTALSPENT.toFixed(2)}</S.TableCell>
                                <S.TableCell>R$ {valorGanho(user.TOTALSPENT, user.RENDIMENTO_ATUAL).toFixed(2)}</S.TableCell>
                                <S.TableCell>{user.RENDIMENTO_ATUAL ? user.RENDIMENTO_ATUAL.toFixed(2) : '00.00'}%</S.TableCell>
                                <S.TableCell>{formatDate(user.YIELDTERM)}</S.TableCell>
                                <S.TableCell>{handleStatus(user.STATUS)}</S.TableCell>
                            </S.TableRow>
                        ))}
                    </S.TableBody>
                </S.Table>
            </S.TableContainer>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={page => setCurrentPage(page)}
            />
        </S.ContratosContainer>
    );
}


