import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import * as S from './RedefinirEmailStyle';
import axios from "axios";
import Loading from "../Loader";

const BASE = process.env.REACT_APP_API_BASE_URL;
const ROUTE = process.env.REACT_APP_REDEFINE_AUTH;

export default function RedefinirEmail() {
    const { clients, loading } = useSelector(state => state.clients);
    const [cpfCliente, setCpfCliente] = useState("");
    const [nomeCliente, setNomeCliente] = useState("");
    const [selected, setSelected] = useState(null);
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [load, setLoad] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);

    const handleRedefinirEmail = async () => {
        setLoad(true)
        if (pass !== confirmPass) {
            alert("As senhas não coincidem!");
            setLoad(false);
            return;
        }

        try {
            axios.post(`${BASE}${ROUTE}`, {
                newEmail: email,
                newPassword: pass,
                cpfCliente: cpfCliente
            }).then(res => {
                console.log("Authentication Redefined");
                alert("Email e Senha alterados com sucesso.")
                console.log(res);
                setLoad(false);
                setConfirmPass("")
                setPass("")
                setCpfCliente("")
                setEmail("")
                setSelected(null);
                setNomeCliente("");
            }).catch(error => {
                console.log("Ocorreu um erro ao atualizar email e senha: ");
                console.log(error);
                alert("Ocorreu um erro ao redefinir email e senha.");
                setLoad(false);
            })
        } catch (error) {
            setLoad(false);
            console.error("Erro ao redefinir email:", error);
            if (error.code === 'auth/requires-recent-login') {
                alert("Por favor, faça login novamente e tente esta operação.");
            } else {
                alert("Erro ao redefinir email. Por favor, tente novamente.");
            }
        }
    };

    useEffect(() => {
        if (selected) {
            setEmail(selected.EMAIL || "");
            setCpfCliente(selected.CPF || "");
        }
    }, [selected]);

    useEffect(() => {
        if (nomeCliente.trim() !== "") {
            const filteredClients = clients.filter(client =>
                client.NAME.toLowerCase().includes(nomeCliente.toLowerCase())
            );
            setSearchResults(filteredClients);
            setShowResults(true);
        } else {
            setSearchResults([]);
            setShowResults(false);
        }
    }, [nomeCliente, clients]);

    const handleClientSelect = (client) => {
        setSelected(client);
        setNomeCliente(client.NAME);
        setShowResults(false);
    };

    return (
        <S.ContainerRedefinirSenha>
            <Loading load={load || loading} />
            <S.ImageBackground src="logo-golden.png" />

            <S.ContainerTitle>Controle de Usuário</S.ContainerTitle>

            <S.ContainerContent>
                <S.RedefineBox>
                    <h2>Redefina Email e senha</h2>

                    <S.Box className="meuDeus">
                        <p>Pesquise</p>
                        <input
                            type="text"
                            value={nomeCliente}
                            onChange={(e) => {setNomeCliente(e.target.value); console.log(e.target.value)}}
                            onFocus={() => setShowResults(true)}
                        />

                        {showResults && searchResults.length > 0 && (
                            <div className="searchResult">
                                {searchResults.map((client) => (
                                    <div
                                        key={client.CPF}
                                        className="item"
                                        onClick={() => handleClientSelect(client)}
                                    >
                                        {client.NAME}
                                    </div>
                                ))}
                            </div>
                        )}
                    </S.Box>

                    {selected && (
                        <>
                            <S.Box>
                                <p>CPF Cliente</p>
                                <input
                                    readOnly
                                    type="text"
                                    value={cpfCliente}
                                />
                            </S.Box>

                            <S.Box>
                                <p>Novo Email</p>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </S.Box>

                            <S.Box>
                                <p>Nova Senha</p>
                                <input
                                    type="text"
                                    value={pass}
                                    onChange={(e) => setPass(e.target.value)}
                                />
                            </S.Box>

                            <S.Box>
                                <p>Confirme a Senha</p>
                                <input
                                    type="text"
                                    value={confirmPass}
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                />
                            </S.Box>

                            {(cpfCliente.trim() !== "" && email.trim() !== ""
                                && pass.trim() !== "" && confirmPass.trim() !== "") && (
                                    <S.RedefineButton onClick={handleRedefinirEmail}>Redefinir</S.RedefineButton>
                                )}
                        </>
                    )}
                </S.RedefineBox>
            </S.ContainerContent>
        </S.ContainerRedefinirSenha>
    )
}
